<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="
          background-image: url('https://i.pinimg.com/originals/1c/d8/47/1cd84761e613e417f08ea18f3586a884.jpg');
        "
      >
        <div class="wrapper">
          <div class="content">
            <h1>
              <a class="title" title="Apps">Apps</a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container section-demo-apps">
      <div class="row demo-header">
        <div class="col-md-6">
          <h2>Demo Apps</h2>
        </div>
        <div class="col-md-6 app-stores">
          <br />
          <a
            class="btn"
            href="https://apps.apple.com/us/developer/tbs-solutions-sdn-bhd/id1203168018"
            target="_blank"
          >
            <i class="ion-social-apple"></i>
            TBS on App Store
          </a>
          <a
            class="btn"
            href="https://play.google.com/store/apps/developer?id=TBS+Solutions+Sdn.+Bhd."
            target="_blank"
          >
            <i class="ion-social-android"></i>
            TBS on Google Play
          </a>
        </div>
      </div>
      <div class="row demo-app">
        <div class="col-md-5">
          <img src="epandu Downloade Sam.jpg" class="img-responsive" />
        </div>
        <div class="col-md-7">
          <h2>ePandu</h2>
          <p>
            ePandu app is designed for students who wish to take driving
            license. This application is to assist driving students to pass
            their driving test easily.
          </p>
          <div>
            <a
              href="https://apps.apple.com/us/app/epandu/id1461438972"
              target="_blank"
            >
              <img
                alt="Get it on App Store"
                src="https://library.acs.com.hk/images/btn-app-store.png"
              />
            </a>
            &nbsp;&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=my.com.tbs.epandu.app&hl=en_US&gl=US"
              target="_blank"
            >
              <img
                alt="Get it on Google Play"
                src="https://library.acs.com.hk/images/btn-play-store.png"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="row demo-app">
        <div class="col-md-5">
          <img
            src="TBS-Digitalisation-grant_1920-x-1080.jpg"
            class="img-responsive"
          />
        </div>
        <div class="col-md-7">
          <h2>Carser Merchant</h2>
          <p>
            Run the powerful features of Carser Merchant System on your mobile
            phones and tablets. Carser merchant is an app for warehouses to
            manage their stocks. Enable user to view products and check the
            stock balances all in one place. User can also keep track the
            vehicle service histories and open new job sheets.
          </p>
          <div>
            <a
              href="https://apps.apple.com/us/app/carser-merchant/id1457930363"
              target="_blank"
            >
              <img
                alt="Get it on App Store"
                src="https://library.acs.com.hk/images/btn-app-store.png"
              />
            </a>
            &nbsp;&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=my.com.tbs.carser.merchant.app"
              target="_blank"
            >
              <img
                alt="Get it on Google Play"
                src="https://library.acs.com.hk/images/btn-play-store.png"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="row demo-app">
        <div class="col-md-5">
          <img src="Esales Image sample .jpg" class="img-responsive" />
        </div>
        <div class="col-md-7">
          <h2>eSales</h2>
          <p>
            Run the powerful features of TBS eSales app on your mobile phones
            and tablets. It is use for sales person take customer order, payment
            collection and check stocks. Enable user to do this function all in
            one app. It direct link to back end system, reduce work force and
            data error. It also can tracking sales person job activity.
          </p>
          <div>
            <a
              href="https://apps.apple.com/us/app/tbs-esales/id1475077204"
              target="_blank"
            >
              <img
                alt="Get it on App Store"
                src="https://library.acs.com.hk/images/btn-app-store.png"
              />
            </a>
            &nbsp;&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=my.com.tbs.esales.app"
              target="_blank"
            >
              <img
                alt="Get it on Google Play"
                src="https://library.acs.com.hk/images/btn-play-store.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Apps"
};
</script>
